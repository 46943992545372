import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";

const genricStateSlice = createSlice({
	name: "genricStateDetails",
	initialState: {
		stateDetails: "",
	},
	reducers: {
		clear: (state) => {
			state.stateDetails = [];
		},
		updateStateDetails: (state, { payload }) => {
			state.stateDetails = { ...state.stateDetails, ...payload };
		},
	},
	extraReducers: {},
});

export const { clear, updateStateDetails } = genricStateSlice.actions;

export const fetchGenricStateDetails = getSelector(
	"genricStateSlice",
	"stateDetails"
);
export default genricStateSlice.reducer;

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import { Typography } from "../../../components/DesignSystem/Typography";
import { InputField } from "../../../components/DesignSystem/FormField";
import { ToggleSwitch } from "../../../components/DesignSystem/ToggleSwitch";
import { addPayment, fetchPaymentDetails } from "../../../action";
import { selectPaymentInfo } from "../../../redux/paymentSlice";
import { PaymentShema } from "../../../validationShema/PaymentShema";
const checkBoxValues = { left: "YES", right: "NO" };
const resData = {
  info: {
    bank_name: "",
    account_name: "",
    account_no: "",
    is_enable_disable_bank_payment: "",
    is_enable_disable_cash_payment: "",
    bsb: "",
  },
};
const PaymentSetting = () => {
  const [bankPaymentState, setBankPaymentState] = useState("NO");
  const [cashPaymentState, setCashPaymentState] = useState("NO");
  const pymentDetails = useSelector(selectPaymentInfo);

  const dispatch = useDispatch();
  const formOptions = {
    resolver: yupResolver(PaymentShema),
  };
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm(formOptions, {
    defaultValues: {
      ...resData,
    },
  });

  useEffect(() => {
    dispatch(fetchPaymentDetails());
  }, []);

	useEffect(() => {
		if (pymentDetails) {
			setValue("info", pymentDetails);
			setBankPaymentState(
				pymentDetails && pymentDetails?.is_enable_disable_bank_payment
			);
			setCashPaymentState(
				pymentDetails && pymentDetails?.is_enable_disable_cash_payment
			);
		}
	}, [pymentDetails, pymentDetails?.is_enable_disable_bank_payment, setValue]);

	const onSubmit = ({ info }) => {
		dispatch(
			addPayment({
				...info,
				is_enable_disable_bank_payment: bankPaymentState,
				is_enable_disable_cash_payment: cashPaymentState,
			})
		);
	};
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Payment Setting</Typography>
			</div>
			<div className="container p-2">
				<div className="p-2">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row border rounded mb-4">
							<div className="col-md-6 p-3">
								Enable / Disable Bank Payment in App
							</div>
							<div className="col-md-6 p-3 text-end">
								<Controller
									name="info.is_enable_disable_bank_payment"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<ToggleSwitch
											name={name}
											value={bankPaymentState}
											getSelectedValue={(val) => {
												setValue("info.is_enable_disable_bank_payment", val);
												setBankPaymentState(val);
											}}
											checkBoxValues={checkBoxValues}
											errors={errors}
											//onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.bank_name"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Bank Name"
											errorMessage="Bank name"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.account_name"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Account Name"
											errorMessage="Account name"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.account_no"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="Account No"
											errorMessage="Account no"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<Controller
									name="info.bsb"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<InputField
											name={name}
											value={value}
											errors={errors}
											placeholder="BSB"
											errorMessage="BSB"
											type="text"
											onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
						</div>
						<div className="row border rounded p-3">
							<div className="col-md-6">
								Enable / Disable Cash Payment in App
							</div>
							<div className="col-md-6 text-end">
								<Controller
									name="info.is_enable_disable_cash_payment"
									control={control}
									render={({ field: { onChange, name, value, ref } }) => (
										<ToggleSwitch
											name={name}
											value={cashPaymentState}
											getSelectedValue={(val) => {
												setValue("info.is_enable_disable_cash_payment", val);
												setCashPaymentState(val);
											}}
											checkBoxValues={checkBoxValues}
											errors={errors}
											//onChange={onChange}
											ref={ref}
										/>
									)}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-end mt-5">
							<OutlineButton label="Save" />
						</div>
					</form>
				</div>
			</div>
		</>
	);
};
export default PaymentSetting;

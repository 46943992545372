import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import { addProduct, fetchCategoryDetails } from "../../../action";
import { ProductShema } from "../../../validationShema/ProductShema";
import { Typography } from "../../../components/DesignSystem/Typography";
import { useMyNavigation } from "../../../hooks";
import ProductForm from "./ProductForm";
import { selectTostInfo } from "../../../redux/tostSlice";
import { fetchGenricStateDetails } from "../../../redux/genricStateSlice";
import AddVariantImage from "./AddVariantImage";
import { selectUnitType } from "../../../redux/categorySlice";

const resData = {
	info: {
		isEdit: false,
		product_name: "",
		// p_image: "",
		quantity_alert: "",
		product_category: "",
		category: "",
		//product_description: "",
		//unit_type: "",
		varientArray: [],
	},
};
const AddProduct = () => {
	const { push } = useMyNavigation();
	const dispatch = useDispatch();
	const getTostInfo = useSelector(selectTostInfo);
	const imageRef = useRef();
	const getImages = useSelector(fetchGenricStateDetails);
	const unitType = useSelector(selectUnitType);

	const formOptions = {
		resolver: yupResolver(ProductShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		getValues,
	} = useForm(formOptions, {
		defaultValues: {
			...resData,
		},
	});
	useEffect(() => {
		dispatch(fetchCategoryDetails());
	}, [dispatch]);

	useEffect(() => {
		getTostInfo?.isModal && push("/products");
	}, [getTostInfo, push]);

	const onSubmit = ({ info }) => {
		return;
		dispatch(
			addProduct({
				...info,
				// image: info.p_image,
				category: info?.category?.value,
				parent_sub_category: info?.product_category?.value,
				is_available: info?.is_available?.value,
				//unit_type: JSON.stringify(info?.unit_type),
				varientImages: getImages,
			})
		);
	};
	const uploadPhotoHandler = (index) => {
		imageRef.current.handleGallery(index);
	};
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Create Product</Typography>
				<OutlineButton label="Back" onClick={() => push("/products")} />
			</div>
			<div className="container p-2">
				<div className="p-2">
					<form onSubmit={handleSubmit(onSubmit)}>
						<ProductForm
							control={control}
							errors={errors}
							setValue={setValue}
							getValues={getValues}
							addPhotoGallery={uploadPhotoHandler}
							unitType={unitType}
						/>
						<div className="d-flex justify-content-end mt-3">
							<OutlineButton label="Add Product" />
						</div>
					</form>{" "}
					<AddVariantImage ref={imageRef} />
				</div>
			</div>
		</>
	);
};
export default AddProduct;

import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { SelectField } from "../../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../../components/DesignSystem/Buttons";
import ModalBox from "../../../../components/DesignSystem/ModalBox";
import { OrderStatus } from "../../../../config/nav-config";
import { filterObjectByValue } from "../../../../util";
import { ChangeOrderStatus } from "../../../../action";

const resData = {
	info: {
		order_status: "",
		id: "",
	},
};
const OrderChangeStatus = forwardRef(({}, ref) => {
	const dispatch = useDispatch();
	const openModal = useRef();
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			...resData,
		},
	});

	useImperativeHandle(ref, () => ({
		handleOrderChange(item, id, type) {
			if (item) {
				//console.log({ item });
				setValue("info.order_status", filterObjectByValue(OrderStatus, item));
				setValue("info.id", id);
				setValue("info.type", type);
			} else {
				reset(resData);
			}
			openModal.current.handleModalBox();
		},
	}));

	const onSubmit = ({ info }) => {
		dispatch(
			ChangeOrderStatus({
				...info,
				order_status: info.order_status?.value,
			})
		);

		reset({ ...resData });
	};
	return (
		<ModalBox ref={openModal} size="sm" title={`Edit Order Status`}>
			<div className="container p-2">
				<div className="p-2">
					<form className="box" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-12 mt-3 mb-3">
								<Controller
									name="info.order_status"
									control={control}
									render={({ field: { onChange, value, name, ref } }) => (
										<SelectField
											name={name}
											value={value}
											optionsItems={OrderStatus}
											errors={errors}
											placeholder="Please select a status"
											errorMessage="Please select a status."
											ref={ref}
											onChange={onChange}
										/>
									)}
								/>
							</div>
						</div>

						<div className="d-flex justify-content-end mt-1">
							<OutlineButton label="Edit Status" />
						</div>
					</form>
				</div>
			</div>
		</ModalBox>
	);
});
export default OrderChangeStatus;

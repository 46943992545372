import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { clearModal, requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addProduct = createAsyncThunk(
	"productSlice/addProduct",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "product",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchProductList());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchProductList = createAsyncThunk(
	"productSlice/fetchProductList",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "product",
				filter: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchProductDetailsWithoutRefrash = createAsyncThunk(
	"productSlice/fetchProductDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			const param = {
				method: "getById",
				endpoint: "product",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchProductDetails = createAsyncThunk(
	"productSlice/fetchProductDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "getById",
				endpoint: "product",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const deleteProduct = createAsyncThunk(
	"productSlice/deleteProduct",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteProduct",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchProductList());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateProductStatus = createAsyncThunk(
	"ProductSlice/updateProductStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateProductStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchProductList());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateProductAvalibiltyStatus = createAsyncThunk(
	"ProductSlice/updateProductAvalibiltyStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateProductAvalibiltyStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchProductList());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchProductVarientImages = createAsyncThunk(
	"productSlice/fetchProductVarientImages",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			//dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "getVarientImages",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					//TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const deleteProductVarientImage = createAsyncThunk(
	"productSlice/deleteProductVarientImage",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			//dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteVarientImage",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0, 1);
					dispatch(fetchProductVarientImages({ id: object?.vid }));
					dispatch(fetchProductDetailsWithoutRefrash(object?.product_id));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const editProductVarientImage = createAsyncThunk(
	"productSlice/addProductVarientImage",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			//dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "editVarientImage",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0, 1);
					dispatch(fetchProductVarientImages({ id: object?.id }));
					dispatch(fetchProductDetailsWithoutRefrash(object?.product_id));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchLowOutOfStockProducts = createAsyncThunk(
	"productSlice/fetchLowOutOfStockProducts",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "lowOutOfStockProducts",
				filter: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchProductCategoryList = createAsyncThunk(
	"productSlice/fetchProductCategoryList",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "productCategoryList",
				filter: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

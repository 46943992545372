import { HashRouter, Route, Routes } from "react-router-dom";
import { Login } from "../../container/Login";
import "./App.css";
import { PublicRoute } from "../../Routes";

import { Layout } from "../Layout";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../../action/userAction";
import { PrivateRoute } from "../../Routes/PrivateRoute";
import {
  clear,
  clearNotification,
  selectTostInfo,
} from "../../redux/tostSlice";
import { Alert } from "../Alert";
import Loader from "../Loader";
import ErrorModal from "../ErrorModal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { RequestDelete } from "../../container/RequestDelete";

function App() {
  const dispatch = useDispatch();
  const ref = useRef();
  const { isAuthenticate } = useIsAuthenticate();

  const getTostInfo = useSelector(selectTostInfo);

  useEffect(() => {
    localStorage.getItem("access_token") && dispatch(fetchUserDetails());
  }, [dispatch, localStorage.getItem("access_token")]);

  useEffect(() => {
    const callAlerts = (getTostInfo) => {
      dispatch(clearNotification());
      ref.current.handleClick(getTostInfo);
    };
    getTostInfo?.isNotification && callAlerts(getTostInfo);
  }, [dispatch, getTostInfo]);
  return (
    <HashRouter>
      <Routes>
        {isAuthenticate ? (
          <Route exact element={<PrivateRoute />}>
            <Route exact path="/*" element={<Layout />} />
          </Route>
        ) : (
          <Route exact element={<PublicRoute />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/delete-user" element={<RequestDelete />} />
          </Route>
        )}
      </Routes>
      <Alert ref={ref} />
      {getTostInfo?.isLoading && <Loader />}
      {getTostInfo?.isErorModalCLose && (
        <ErrorModal getTostInfo={getTostInfo} />
      )}
    </HashRouter>
  );
}

export default App;

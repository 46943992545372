import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { getArrayIndexByArrayObjectValue, getKeyByValue } from "../../../util";

const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .album-nav {
    font-weight: 600;
    cursor: pointer;
    gap: 15px;
  }
`;
const NavTabs = ({ getActiveNavValue, navItems, defaultItem = 0 }) => {
  const [activeNav, setActiveNav] = useState(defaultItem);
  
  useEffect(() => {
    setActiveNav(
      navItems[getArrayIndexByArrayObjectValue(navItems, defaultItem) || 0]
    );
  }, [defaultItem, navItems]);

  return (
    <Container>
      <ul className="nav album-nav border border-bottom-0  d-flex">
        {navItems.map(({ title, value, count }, key) => {
          return (
            <div key={key} className="">
              <li
                onClick={() => {
                  setActiveNav({ title: title, value: value });
                  getActiveNavValue({ title: title, value: value });
                }}
                className={`nav-item  p-2 ${
                  activeNav?.value === value
                    ? "border-bottom border-danger text-danger"
                    : ""
                }`}
                // " border-danger text-danger "
                key={key}
              >
                {title} {count && `(${count})`}
              </li>
              <div className="album-title"></div>
            </div>
          );
        })}
      </ul>
    </Container>
  );
};

export default NavTabs;

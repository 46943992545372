import React, { forwardRef, useEffect, useRef, useState } from "react";

import RecipientDetails from "./RecipientDetails";
import { useLocation } from "react-router-dom";
import { useMyNavigation } from "../../../../hooks";
import { Typography } from "../../../../components/DesignSystem/Typography";
import { OutlineButton } from "../../../../components/DesignSystem/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetailsById } from "./../../../../action/orderAction";
import { getOrderDetailsById } from "../../../../redux/orderSlice";
import OrderChangeStatus from "./OrderChangeStatus";
import OrderChangeAddress from "./OrderChangeAddress";
import OrderChangePaymentStatus from "./OrderChangePaymentStatus";

const ViewOrder = forwardRef(({}, ref) => {
	const dispatch = useDispatch();
	const { state } = useLocation();
	const orderChangeModal = useRef();
	const orderChangeAddressModal = useRef();
	const orderChangePaymentModal = useRef();
	const { push } = useMyNavigation();
	const getOrderDetails = useSelector(getOrderDetailsById);

	useEffect(() => {
		dispatch(fetchOrderDetailsById(state?.item?.id));
	}, [dispatch]);

	const editStatusAddress = (item, id, type) => {
		if (type === "S") {
			orderChangeModal.current.handleOrderChange(item, id, type);
		} else if (type === "PS") {
			orderChangePaymentModal.current.handleOrderPaymentStatusChange(
				item,
				id,
				type
			);
		} else {
			orderChangeAddressModal.current.handleOrderChangeAddress(item, id, type);
		}
	};

	return (
		<div className="p-2 g-1">
			<div className="d-flex justify-content-between mb-2">
				<Typography align="text-start">Order Details</Typography>
				<OutlineButton label="Back" onClick={() => push("/order")} />
			</div>
			<div className="shadow-sm rounded p-3 bg-light d-flex justify-content-between">
				<div> Order: {getOrderDetails?.order_no}</div>
				<div>Order Date: {getOrderDetails?.created_at}</div>
				<div>
					<a
						href={`${
							process.env.REACT_APP_BASE_URL + "/api/"
						}oz-food-odering-order-invoice-pdf?id=${window.btoa(
							getOrderDetails?.id
						)}`}
						target="_blank"
					>
						Invoice
					</a>
				</div>
			</div>

			<RecipientDetails
				orderDetails={getOrderDetails}
				editStatusAddress={editStatusAddress}
			/>
			<OrderChangeStatus ref={orderChangeModal} />
			<OrderChangeAddress ref={orderChangeAddressModal} />
			<OrderChangePaymentStatus ref={orderChangePaymentModal} />
		</div>
	);
});
export default ViewOrder;

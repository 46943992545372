import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectProductVarientsImagesList } from "../../../redux/productSlice";
import {
	deleteProductVarientImage,
	editProductVarientImage,
	fetchProductVarientImages,
} from "../../../action";
import { CloseIcon } from "../../../components/DesignSystem/Icons/Icons";
import { useForm } from "react-hook-form";
import { Image } from "../../../components/DesignSystem/Image";
const resData = {
	info: {
		varientImage: "",
		id: "",
	},
};
export const EditVariantImage = forwardRef(({}, ref) => {
	const openModal = useRef();
	const imageRef = useRef();
	const dispatch = useDispatch();
	const getVarientsImages = useSelector(selectProductVarientsImagesList);
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
	} = useForm({
		defaultValues: {
			...resData,
		},
	});
	useImperativeHandle(ref, (item) => ({
		handleGallery(item) {
			setValue("info", item);
			dispatch(fetchProductVarientImages({ id: item }));
			openModal.current.handleModalBox();
		},
	}));
	const handleImageDelete = ({ id, image, variant_id }) => {
		dispatch(
			deleteProductVarientImage({
				id: id,
				imageFile: image,
				vid: variant_id,
				product_id: getValues("info.product_id"),
			})
		);
	};
	const onSubmit = (obj, e) => {
		let allowedExtension = [
			"image/jpeg",
			"image/jpg",
			"image/png",
			"image/PNG",
			"image/JPG",
			"image/JPEG",
		];
		const file = e.target.e_image_name.files[0];
		//	console.log(file.type);

		if (file === undefined) {
			return;
		}
		if (allowedExtension.indexOf(file.type) > -1) {
			obj.info.varientImage = e.target.e_image_name.files[0];
			const data = obj.info;
			dispatch(editProductVarientImage(data));
			imageRef.current.resetImage("");
		} else {
			alert("Plaese select valid image format(jpg,png)");
			return;
		}
	};
	return (
		<ModalBox ref={openModal} size="lg" title="Manage Image">
			<Container>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row  text-center">
						<div className="col-4 ">
							<Image
								errors={errors}
								control={control}
								setValue={setValue}
								ref={imageRef}
								isLabel={false}
								image_name="e_image_name"
							/>
						</div>
						{getVarientsImages &&
							getVarientsImages?.map((imageItem, i) => (
								<div className="col-4 remove-btn-div" key={i}>
									<div
										className="remove-btn position-absolute cp"
										onClick={() => handleImageDelete(imageItem)}
									>
										<CloseIcon size={30} color="#061b35" />
									</div>

									<img
										src={`${process.env.REACT_APP_BASE_URL}/public${imageItem?.image}`}
										alt="..."
										className="img-box  shadow-sm"
									></img>
								</div>
							))}
					</div>
					<div className="text-end mt-4">
						<OutlineButton label="Upload" />
					</div>
				</form>
			</Container>
		</ModalBox>
	);
});
const Container = styled.div.attrs(() => ({
	className: "container px-4",
}))`
	margin-bottom: 2rem;
	.img-box {
		width: 100%;
		max-height: 150px;
		min-height: 150px;
		border-radius: 5px;
		object-fit: cover;
		margin-top: 1.7rem;
	}
	.submit-box {
	}
	.img-sec {
		width: 100%;
		max-height: 150px;
		min-height: 150px;
	}
	.remove-btn {
		margin-top: 26px;
		z-index: 99;
		border-radius: 5px;
		opacity: 0;
	}
	.remove-btn-div:hover {
		.remove-btn {
			opacity: 1;
		}
	}
`;
